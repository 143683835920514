<template>
  <div :class="platform" class="block">
    <h3 class="block-tilte">
      <slot name="block-title"> {{ props.title }}</slot>
    </h3>

    <dl class="block-list">
      <slot></slot>
    </dl>
  </div>
</template>

<script setup lang="ts">
const { platform } = usePlatform()

const props = defineProps<{
  title?: string
}>()
</script>

<style scoped>
.block-tilte {
  margin: 0;
  font: var(--desktop-text-md-semibold);
  color: var(--text-primary);
}

.block-list {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-150);

  margin: 0;
  padding: var(--spacing-150);

  background: var(--background-primary);
  border-radius: var(--border-radius-100);
}

.block {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-100);

  &.mobile {
    .block-title {
      font: var(--mobile-text-semibold);
    }

    .block-list {
      gap: var(--spacing-125);
      padding: var(--spacing-125) var(--spacing-150);
    }
  }
}
</style>
