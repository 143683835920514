<template>
  <section class="tooltip-modal" :class="platform">
    <header class="modal-header">
      <h2 class="title">
        <slot name="title"></slot>
      </h2>
      <p class="subtitle">
        <slot name="subtitle"></slot>
      </p>
      <button v-if="isMobile" class="close-button" @click="close">
        <StIcon name="cross-large" :size="20" />
      </button>
    </header>
    <div class="content">
      <slot></slot>
    </div>

    <NuxtI18nLink
      class="read-more"
      :to="link"
      target="_blank"
      data-t="read-more"
    >
      <span>{{ t('bonuses.bonusReadMore') }}</span>
      <StIcon name="chevron-right" :size="16" />
    </NuxtI18nLink>
  </section>
</template>

<script setup lang="ts">
interface Props {
  link: string
}
defineProps<Props>()
const { t } = useI18n()
const router = useRouter()
function close() {
  router.replace({ query: {} })
}

const { platform, isMobile } = usePlatform()
</script>

<style scoped>
.modal-header {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: var(--spacing-100);
}

.title {
  margin: 0;
  font: var(--desktop-text-xl-semibold);
  color: var(--text-primary);
}

.subtitle {
  margin: 0;
  font: var(--desktop-text-sm-medium);
  color: var(--text-tertiary);
}

.content {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-200);
}

.read-more {
  display: flex;
  gap: var(--spacing-075);
  align-items: center;

  font: var(--desktop-text-sm-semibold);
  color: var(--text-tertiary);
  text-decoration: none;

  &:hover {
    color: var(--text-primary);
  }
}

.tooltip-modal {
  overflow: hidden;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  gap: var(--spacing-300);

  width: 480px;
  max-height: 668px;
  padding: var(--spacing-300);

  background: var(--background-base);
  border-radius: var(--border-radius-150);
  box-shadow: 0 0 80px -8px #1d1d2a;

  &.mobile {
    gap: var(--spacing-100);
    width: 100%;
    max-height: 100%;
    padding: var(--spacing-400) var(--spacing-200) var(--spacing-200)
      var(--spacing-200);

    .modal-header {
      gap: var(--spacing-050);
      padding: var(--spacing-150) 0;
    }

    .title {
      font: var(--mobile-title-2-semibold);
    }

    .subtitle {
      font: var(--mobile-text-content-regular);
    }

    .content {
      gap: var(--spacing-200);
    }

    .read-more {
      justify-content: center;
      margin-top: var(--spacing-100);
    }
  }
}

.close-button {
  all: unset;

  position: absolute;
  top: 4px;
  right: -12px;

  padding: var(--spacing-125);

  line-height: 0;
}
</style>
